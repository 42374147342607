class NumberCodec {
  constructor () {
    this._name = "Number";
  }

  encode (data) {
    return data;
  }

  decode (data) {
    return Number(data);
  }
}

class BooleanCodec {
  constructor () {
    this._name = "Boolean";
  }

  encode (data) {
    return data ? 1 : 0;
  }

  decode (data) {
    return data == "1";
  }
}
  
class StringCodec {
  constructor () {
    this._name = "String";
  }

  encode (data) {
    return encodeURIComponent(data);
  }

  decode (data) {
    return decodeURIComponent(data);
  }
}

class EnumCodec {
  constructor (name, spec) {
    this._name = name;
    this._values = spec;
  }

  encode (data) {
    return this._values.indexOf(data);
  }

  decode (index) {
    return this._values[index];
  }
}

class ArrayCodec {
  constructor (name, codec) {
    this._name = name;
    this._codec = codec;
  }

  encode (data) {
    const s = data.map(v => encodeURIComponent(this._codec.encode(v))).join(",");
    return `[${s}]`;
  }
  
  decode (data) {
    const m = data.match(/\[([^]*)\]/);
    const body = m[1];
    if (body) {
      const items = body.split(',');
      return items.map(v => this._codec.decode(decodeURIComponent(v)));
    } else {
      return [];
    }
  }
}

const BUILTIN_CODECS = {
  "Number": new NumberCodec(),
  "Boolean": new BooleanCodec(),
  "String": new StringCodec(),
};

export class QueryCodec {
  constructor (name, spec, config) {
    this._name = name;
    this._spec = spec;
    this._subTypes = {};
    this._config = config || {};
  }

  addSubType(name, codec) {
    if (Array.isArray(codec)) {
      this._subTypes[name] = new EnumCodec(name, codec);
      return;
    } else {
      this._subTypes[name] = codec;
      return;
    }
    console.log(`Invalid subType: ${name}`);
  }

  _getIdAndCodec(propName) {
    for (const key in this._spec) {
      if (this._spec[key][propName] !== undefined) {
        let id;
        if (this._config.rawKeyName) {
          id = propName;
        } else {
          id = this._spec[key][propName].toString(16);
        }
        return [id, this._getCodec(key)];
      }
    }
    return [null, undefined];
  }

  _getPropNameAndCodec(id) {
    for (const key in this._spec) {
      for (const propName in this._spec[key]) {
        if (this._spec[key][propName] === id) {
          return [propName, this._getCodec(key)];
        }
        if (this._spec[key][propName].toString(16) === id) {
          return [propName, this._getCodec(key)];
        }
        if (propName === id) {
          return [propName, this._getCodec(key)];
        }
      }
    }
    return [null, undefined];
  }

  _getCodec(type) {
    const m = type.match(/\[([^]+)\]/);
    if (m) {
      const s = this._getCodec(m[1]);
      return new ArrayCodec(m[1], s);
    }
    if (BUILTIN_CODECS[type]) {
      return BUILTIN_CODECS[type];
    }
    if (this._subTypes[type]) {
      return this._subTypes[type];
    }
    return null;
  }

  encode(data) {
    const result = [];
    for (const k in data) {
      const [id, codec] = this._getIdAndCodec(k);
      if (!id) {
        if (this._config.warnUndefinedProp) {
          console.error(`invalid codec: ${id} (${k})`);
        }
        continue;
      }
      const s = codec.encode(data[k]);
      result.push(`${id}=${s}`);
    }
    return result.join("&");
  }

  decode(data) {
    const result = {};
    const params = new URLSearchParams(data);
    for (const k of params.keys()) {
      const [propName, codec] = this._getPropNameAndCodec(k);
      if (!propName) {
        if (this._config.warnUndefinedProp) {
          console.error(`invalid codec: ${propName} (${k})`);
        }
        continue;
      }
      const s = codec.decode(params.get(k));
      result[propName] = s;
    }
    return result;
  }
}

//exports.QueryCodec = QueryCodec;
