import { QueryCodec } from './query-codec';

const emscdSpec = {
  "Number": {
    "boxWidth": 1,
    "boxHeight": 2,
    "rows": 3,
    "topOffset": 5,
    "verticalOffset": 16,
  },
  "Boolean": {
    "flipRailTop": 7,
    "flipRailBottom": 9,
    "showFrontPanels": 10,
    "showRails": 11,
    "showMountHoles": 12,
    "showDimensions": 13,
  },
  "Rail": {
    "railTypeTop": 6,
    "railTypeBottom": 8,
  },
  "Position": {
    "positioningMode": 4,
  },
  "Additional1uRowLayout": {
    "additional1uRow": 17,
  },
  "[middleRail]": {
    "middleRails": 15,
  },
};

const middleRailSpec = {
  "Boolean": {
    flip: 1,
  },
  "Rail": {
    "type": 2,
  },
};

const RailSpec = [ "ffr43n", "fsr43e", "frr43e",
                    "fsr43b", "frr43b", "vector" ];
const PositionSpec = [ "center", "top", "bottom" ];
const Additional1uRowLayoutSpec = [ "none", "topIJ", "topPL",
                                    "bottomIJ", "bottomPL", ];

const DEFAULT_OPTION = {
  rawKeyName: false,
  decodeInteger: false,
  warnUndefinedProp: false,
};

function serialize(data, option) {
  option = option || DEFAULT_OPTION;
  const codec = new QueryCodec("emscdParam", emscdSpec, option);
  const middleRailCodec = new QueryCodec("middleRail", middleRailSpec, option);
  middleRailCodec.addSubType("Rail", RailSpec);
  codec.addSubType("middleRail", middleRailCodec);
  codec.addSubType("Rail", RailSpec);
  codec.addSubType("Position", PositionSpec);
  codec.addSubType("Additional1uRowLayout", Additional1uRowLayoutSpec);
  return codec.encode(data);
}

function deserialize(data, option) {
  option = option || DEFAULT_OPTION;
  const codec = new QueryCodec("emscdParam", emscdSpec, option);
  const middleRailCodec = new QueryCodec("middleRail", middleRailSpec, option);
  middleRailCodec.addSubType("Rail", RailSpec);
  codec.addSubType("middleRail", middleRailCodec);
  codec.addSubType("Rail", RailSpec);
  codec.addSubType("Position", PositionSpec);
  codec.addSubType("Additional1uRowLayout", Additional1uRowLayoutSpec);
  return codec.decode(data);
}

exports.serialize = serialize;
exports.deserialize = deserialize;
